import React from 'react';

import Layout from '../components/layout/Layout';
import { Link } from 'gatsby';


const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export-es/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/social-responsability`,

  },


];

const seo={
  title: "Lucha por la corrupción",
  desc:  "Descubre nuestra politica de corrupción",
  image: "",
  pathname: "/lucha-contra-la-corrupcion/",
}

 const PoliticaCorrupcion = ({location}) =>  (

  <Layout location={location} crumbLabel="Lucha contra la corrupción"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<section id="" className="p-10">


    <div className="container font-serif">
        <h1 className="text-lg font-bold">Lucha contra la Corrupción</h1>
    <p>Unilimpio declara públicamente su compromiso con su actuar ético y transparente ante sus grupos de interés, actuando con una filosofía de cero tolerancias a actos que contradigan los valores corporativos que rigen su cultura organizacional. <br/><br/>
    •	Todos los colaboradores, así como los grupos de interés externos con las que se tengan establecidas relaciones comerciales o contractuales, están en la libertad de poner en conocimiento de UNILIMPIO los hechos o circunstancias que puedan ser considerados como actos de fraude, corrupción o soborno.<br/>
•	Los canales anónimos dispuestos por la empresa para receptar denuncias al respecto son: anticorrupcion@unilimpio.com 
<br/>
</p>



    </div>
         
  <Link to="/denuncias-actos-de-corrupcion/#complaintform./" className="mt-4 text-primary-lighter inline-flex items-center text-base text-extrabold">DENUNCIAR
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>

</section>

  

  </Layout>
);

export default PoliticaCorrupcion;
